<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    transition="dialog-transition"
    max-width="700px"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title primary-title>
          <span class="headline">Create Parameter</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="
              dialog = false;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-subheader>General</v-subheader>
          <v-text-field
            prepend-icon="mdi-format-underline"
            :disabled="saving"
            label="Name *"
            v-model="parameterObj.name"
            :rules="[...rules.required, ...rules.name]"
            :counter="20"
            required
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-form-textbox"
            :disabled="saving"
            label="Description"
            v-model="parameterObj.description"
            :rules="rules.description"
            :counter="200"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-ideogram-cjk-variant"
            :disabled="saving"
            label="Chinese Description"
            v-model="parameterObj.chinesedescription"
            :rules="rules.description"
            :counter="200"
          ></v-text-field>
          <v-autocomplete
            prepend-icon="mdi-shape-outline"
            :disabled="saving"
            label="Category *"
            v-model="parameterObj.parametercategory"
            :rules="rules.required"
            clearable
            :items="categoryList"
            item-value="id"
            item-text="name"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.id"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            prepend-icon="mdi-iframe-variable-outline"
            :disabled="saving"
            label="Datatype *"
            v-model="parameterObj.datatype"
            :rules="rules.required"
            clearable
            :items="datatypeList"
            item-value="id"
            item-text="name"
            return-object
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.id"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field
            prepend-icon="mdi-multiplication"
            :disabled="saving"
            label="Multiplication Factor"
            v-model="parameterObj.multiplicationfactor"
            :rules="[...rules.positive]"
            type="number"
            min="0"
          ></v-text-field>
          <v-text-field
            v-if="
              parameterObj.datatype &&
              (parameterObj.datatype.name === 'Float' ||
                parameterObj.datatype.name === 'Double')
            "
            prepend-icon="mdi-scissors-cutting"
            :disabled="saving"
            label="Decimal Precision"
            v-model="parameterObj.maxdecimal"
            :rules="[...rules.positive, ...rules.integer]"
            type="number"
            min="0"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-scale"
            :disabled="saving"
            label="Parameter Unit"
            v-model="parameterObj.parameterunit"
            :rules="rules.unit"
            :counter="5"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-magnify"
            :disabled="saving"
            label="Pre-Analyzer ID *"
            v-model="parameterObj.paid"
            :rules="[...rules.required, ...rules.positive, ...rules.integer]"
            type="number"
            min="0"
            required
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-arrow-expand-down"
            :disabled="saving"
            label="Min *"
            v-model="parameterObj.min"
            :rules="[...rules.required]"
            type="number"
            required
          ></v-text-field>
           <v-text-field
            prepend-icon="mdi-arrow-collapse-down"
            :disabled="saving"
            label="Critical Min *"
            v-model="parameterObj.criticalmin"
            :rules="[...rules.required]"
            type="number"
            required
          ></v-text-field>
           <v-text-field
            prepend-icon="mdi-arrow-expand-up"
            :disabled="saving"
            label="Critical Max *"
            v-model="parameterObj.criticalmax"
            :rules="[...rules.required]"
            type="number"
            required
          ></v-text-field>
           <v-text-field
            prepend-icon="mdi-arrow-collapse-up"
            :disabled="saving"
            label="Max *"
            v-model="parameterObj.max"
            :rules="[...rules.required]"
            type="number"
            required
          ></v-text-field>
          <v-subheader>Protocol Specific</v-subheader>
          <div v-for="(item, index) in this.protocolList" :key="index">
            <v-text-field
              v-if="
                protocol === item.name &&
                parameterObj.datatype &&
                parameterObj.datatype.name === 'String' &&
                item.hasSize
              "
              prepend-icon="mdi-ruler"
              :disabled="saving"
              label="Size *"
              v-model="parameterObj.size"
              :rules="[...rules.required, ...rules.positive, ...rules.integer]"
              type="number"
              min="0"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === 'SQL' && protocol === item.name && item.hasSize"
              prepend-icon="mdi-ruler"
              :disabled="saving"
              label="Size *"
              v-model="parameterObj.size"
              :rules="[...rules.required, ...rules.positive, ...rules.integer]"
              type="number"
              min="0"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasDBAddress"
              prepend-icon="mdi-map-marker"
              :disabled="saving"
              label="DB Address *"
              v-model="parameterObj.dbaddress"
              :rules="[...rules.required, ...rules.positive, ...rules.integer]"
              type="number"
              min="0"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasStartAddress"
              prepend-icon="mdi-magnify"
              :disabled="saving"
              label="Start Address *"
              v-model="parameterObj.startaddress"
              :rules="[...rules.required, ...rules.positive, ...rules.integer]"
              type="number"
              min="0"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasBitnumber"
              prepend-icon="mdi-electric-switch"
              :disabled="saving"
              label="Boolean Bit *"
              v-model="parameterObj.booleanbit"
              :rules="[
                ...rules.required,
                ...rules.positive,
                ...rules.integer,
                ...rules.bit,
              ]"
              type="number"
              min="0"
              max="7"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasNodeID"
              :disabled="saving"
              label="Node ID *"
              prepend-icon="mdi-magnify"
              v-model="parameterObj.nodeid"
              type="string"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasPlcParamName"
              :disabled="saving"
              label="PLC Parameter Name *"
              prepend-icon="mdi-magnify"
              v-model="parameterObj.plc_parameter"
              :rules="rules.plcParam"
              type="string"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasStartOffset"
              prepend-icon="mdi-map-marker"
              :disabled="saving"
              label="Start Offset *"
              v-model="parameterObj.startoffset"
              :rules="[...rules.required, ...rules.positive, ...rules.integer]"
              type="number"
              min="0"
              required
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasDivisionFactor"
              prepend-icon="mdi-division"
              :disabled="saving"
              label="Division Factor *"
              v-model="parameterObj.divisionfactor"
              :rules="[...rules.required, ...rules.positive]"
              type="number"
              min="0"
              required
            ></v-text-field>
            <v-checkbox
              v-if="protocol === item.name && item.hasIsConversion"
              prepend-icon="mdi-sync"
              :disabled="saving"
              label="Is Conversion *"
              v-model="parameterObj.isconversion"
              required
            ></v-checkbox>
            <v-checkbox
              v-if="protocol === item.name && item.isformula"
              prepend-icon="mdi-ab-testing"
              :disabled="saving"
              label="Is Formula"
              v-model="parameterObj.isformula"
              required
            ></v-checkbox>
            <v-text-field
              v-if="protocol === item.name && item.hasFormula"
              :disabled="saving"
              label="Formula"
              prepend-icon="mdi-calculator-variant"
              v-model="parameterObj.formula"
              type="string"
            ></v-text-field>
            <v-text-field
              v-if="protocol === item.name && item.hasDevicetagname"
              :disabled="saving"
              label="Device Tagname *"
              prepend-icon="mdi-devices"
              v-model="parameterObj.devicetagname"
              :rules="rules.required"
              type="string"
              required
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none"
            :loading="saving"
            @click="saveParameter"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      saving: false,
      parameterObj: {},
      valid: true,
      isSaveValid: false,
      rules: {
        required: [(v) => !!v || 'Required'],
        positive: [(v) => !v || v >= 0 || 'Must be positive'],
        integer: [
          (v) => !v || Number.isInteger(Number(v)) || 'Must be Integer',
        ],
        name: [
          (v) => !v || v.length <= 50 || 'Must be less than 50 characters',
          (v) => !v
            || /^[a-zA-Z0-9-_]+$/.test(v)
            || 'Only letters, numbers, and underscores allowed',
        ],
        description: [
          (v) => !v || v.length <= 200 || 'Must be less than 200 characters',
        ],
        bit: [(v) => !v || v <= 7 || 'Must be smaller or equal 7'],
        unit: [
          (v) => !v || v.length <= 5 || 'Must be smaller or equal 5 characters',
        ],
        plcParam: [
          (v) => !v
            || /^([a-zA-Z]+)([a-zA-Z0-9-._]+)/.test(v)
            || 'Invalid PLC Parameter Name',
        ],
      },
    };
  },
  props: [
    'station',
    'substation',
    'line',
    'subline',
    'protocolList',
    'protoTags',
  ],
  computed: {
    ...mapState('parameterConfigurationMes', [
      'addParameterDialog',
      'categoryList',
      'datatypeList',
      'parameterList',
      'selectedParameterName',
      'selectedParameterCategory',
      'selectedParameterDatatype',
      'stationList',
      'protocol',
    ]),
    dialog: {
      get() {
        return this.addParameterDialog;
      },
      set(val) {
        this.setAddParameterDialog(val);
      },
    },
  },
  // watch: {
  //   parameterObj: {
  //     handler(parameterObj) {
  //       if (parameterObj.datatype && parameterObj.datatype.name !== 'String') {
  //         this.parameterObj.size = parameterObj.datatype.size;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapMutations('parameterConfigurationMes', ['setAddParameterDialog']),
    ...mapActions('parameterConfigurationMes', [
      'getPageDataList',
      'createParameter',
      'getParameterListRecords',
      'getSubStationIdElement',
      'validateParameter',
      'resetUploadData',
    ]),
    getQuery() {
      let query = '?query=';
      if (this.selectedParameterName) {
        query += `name=="${this.selectedParameterName}"%26%26`;
      }
      if (this.selectedParameterCategory) {
        query += `parametercategory=="${this.selectedParameterCategory}"%26%26`;
      }
      if (this.selectedParameterDatatype) {
        query += `datatype=="${this.selectedParameterDatatype}"%26%26`;
      }
      query += `substationid=="${this.substation || null}"`;
      query += '&sortquery=sequence==1';
      return query;
    },
    async saveParameter() {
      this.$refs.form.validate();
      this.resetUploadData();
      this.parameterObj.protocol = this.protocol;
      this.parameterObj.datatype = this.datatypeList
        .filter((dt) => dt.name === this.parameterObj.datatype.name)[0].id;
      this.validateParameter([this.parameterObj]);
      this.$root.$emit('parameterCreation', true);
      this.$root.$on('passedFlagOfValidations', (data) => {
        if (data && data.length) {
          this.parameterObj = {};
          this.$refs.form.reset();
          this.dialog = false;
        }
      });
    },
  },
};
</script>
