<template>
  <v-navigation-drawer
    absolute
    right
    :overlay-opacity="0"
    temporary
    v-model="showFilter"
  >
    <v-toolbar flat :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">
      <v-toolbar-title> Parameter filters </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleFilter">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card flat>
      <perfect-scrollbar>
        <v-card-text style="height: calc(100vh - 220px)">
          <v-autocomplete
            class="mt-5"
            :items="lineList"
            outlined
            dense
            hide-details
            v-model="line"
            name="name"
            label="Select Line"
            item-text="name"
            item-value="id"
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="mt-5"
            :items="sublineList"
            outlined
            dense
            hide-details
            v-model="subline"
            name="name"
            label="Select Subline"
            item-text="name"
            item-value="id"
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="mt-5"
            :items="stationList"
            outlined
            dense
            hide-details
            v-model="station"
            name="name"
            label="Select Station"
            item-text="name"
            item-value="id"
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="mt-5"
            :items="substationList"
            outlined
            dense
            hide-details
            v-model="substation"
            name="name"
            label="Select Sub-Station"
            item-text="name"
            item-value="id"
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="mt-5"
            :items="parameterList"
            outlined
            dense
            hide-details
            v-model="parameterName"
            name="name"
            label="Parameter Name"
            item-text="name"
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="mt-5"
            :items="categoryList"
            outlined
            dense
            hide-details
            v-model="parameterCategory"
            name="name"
            label="Parameter Category"
            item-text="name"
            item-value="id"
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="mt-5"
            :items="datatypeList"
            outlined
            dense
            hide-details
            v-model="parameterDatatype"
            name="name"
            label="Parameter Datatype"
            item-text="name"
            item-value="id"
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </perfect-scrollbar>
      <v-card-actions>
        <v-btn
          class="text-none"
          color="primary"
          @click="btnApply"
          :class="$vuetify.theme.dark ? 'black--text' : 'white--text'"
          :disabled="!stationValue"
          :loading="saving"
        >
          Apply
        </v-btn>
        <v-btn class="text-none" color="primary" @click="btnReset" text>
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'OrderFilter',
  created() {},
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    ...mapState('parameterConfigurationMes', [
      'filter',
      'parameterList',
      'lineList',
      'sublineList',
      'stationList',
      'substationList',
      'lineValue',
      'sublineValue',
      'stationValue',
      'substationValue',
      'categoryList',
      'datatypeList',
      'selectedParameterName',
      'selectedParameterCategory',
      'selectedParameterDatatype',
      'protocol',
    ]),
    showFilter: {
      get() {
        return this.filter;
      },
      set(val) {
        this.setFilter(val);
      },
    },
    parameterName: {
      get() {
        return this.selectedParameterName;
      },
      set(val) {
        this.setSelectedParameterName(val);
      },
    },
    parameterCategory: {
      get() {
        return this.selectedParameterCategory;
      },
      set(val) {
        this.setSelectedParameterCategory(val);
      },
    },
    parameterDatatype: {
      get() {
        return this.selectedParameterDatatype;
      },
      set(val) {
        this.setSelectedParameterDatatype(val);
      },
    },
    line: {
      get() {
        return this.lineValue;
      },
      set(val) {
        this.setLineValue(val);
        const query = `?query=lineid==${val}`;
        this.getSublineList(query);
        this.setSublineValue('');
        this.setStationValue('');
        this.setSubstationValue('');
      },
    },
    subline: {
      get() {
        return this.sublineValue;
      },
      set(val) {
        this.setSublineValue(val);
        const query = `?query=sublineid=="${val}"`;
        this.getStationList(query);
        this.setStationValue('');
        this.setSubstationValue('');
      },
    },
    station: {
      get() {
        return this.stationValue;
      },
      set(val) {
        this.setStationValue(val);
        this.getSubstationList(`?query=stationid=="${val}"`);
        this.setSubstationValue('');
      },
    },
    substation: {
      get() {
        return this.substationValue;
      },
      set(val) {
        this.setApply(false);
        this.setSubstationValue(val);
      },
    },
  },
  methods: {
    ...mapMutations('parameterConfigurationMes', [
      'setFilter',
      'toggleFilter',
      'setApply',
      'setLineValue',
      'setSublineValue',
      'setStationValue',
      'setSubstationValue',
      'setSelectedParameterName',
      'setSelectedParameterCategory',
      'setSelectedParameterDatatype',
    ]),
    ...mapActions('parameterConfigurationMes', [
      'getParameterListRecords',
      'getSublineList',
      'getStationList',
      'getSubstationList',
    ]),
    async btnApply() {
      let query = `?query=protocol=="${this.protocol}"`;
      if (this.lineValue) {
        query += `%26%26lineid==${this.lineValue}`;
      }
      if (this.sublineValue) {
        query += `%26%26sublineid=="${this.sublineValue}"`;
      }
      if (this.stationValue) {
        query += `%26%26stationid=="${this.stationValue}"`;
      }
      if (this.substationValue) {
        query += `%26%26substationid=="${this.substationValue}"`;
      }
      if (this.selectedParameterName) {
        query += `%26%26name=="${this.selectedParameterName}"`;
      }
      if (this.selectedParameterCategory) {
        query += `%26%26parametercategory=="${this.selectedParameterCategory}"`;
      }
      if (this.selectedParameterDatatype) {
        query += `%26%26datatype=="${this.selectedParameterDatatype}"`;
      }
      query += '&sortquery=sequence==1';
      this.saving = true;
      await this.getParameterListRecords(query);
      this.saving = false;
      this.setApply(true);
      this.toggleFilter();
    },
    btnReset() {
      this.getParameterListRecords(
        `?query=protocol=="${this.protocol}"&sortquery=sequence==1`,
      );
      this.setLineValue(null);
      this.setSublineValue(null);
      this.setStationValue(null);
      this.setSubstationValue(null);
      this.setSelectedParameterName(null);
      this.setSelectedParameterCategory(null);
      this.setSelectedParameterDatatype(null);
      this.toggleFilter();
    },
  },
};
</script>
