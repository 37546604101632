<template>
  <v-dialog v-model="dialog" max-width="50%">
    <v-card>
      <v-card-title class="headline"
        > Confirmation
        <v-spacer></v-spacer>
        <v-btn icon small @click="cancelCreation">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        v-if="!invalidArray.flat(1).length"
        class="text-center text-h6 text-uppercase success--text">
        Congratulations, no errors found...
      </v-card-text>
      <v-card-text
        v-else
        class="text-center text-h6 text-uppercase error--text">
        Sorry, please check your data again...
      </v-card-text>
        <div v-for="(item, index) in invalidArray" :key="index">
          <v-expansion-panels flat accordion>
            <v-expansion-panel v-if="item.length">
              <v-expansion-panel-header class="error--text">
                {{ $t("validationError["+index+"]") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Row
                        </th>
                        <th class="text-left">
                          Invalid Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in item"
                        :key="index"
                      >
                        <td>{{ data.row }}</td>
                        <td v-if="data[Object.keys(data)[1]]">
                          {{ data[Object.keys(data)[1]] }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="text-none"
          @click="createParamsBtn"
          :loading="savingData"
          :disabled="invalidArray.flat(1).length ? true : false">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'Confirmation-parameter',
  data() {
    return {
      savingData: false,
      dialog: false,
    };
  },
  mounted() {
    this.$root.$on('parameterCreation', (data) => {
      this.dialog = data;
    });
  },
  computed: {
    ...mapState('parameterConfigurationMes', [
      // 'missingInformation',
      'incorrectProtocol',
      'invalidName',
      'invalidDescript',
      'invalidChineseDescript',
      'invalidParameterCategory',
      'invalidDataType',
      'invalidMultiplicationFactor',
      'invalidParameterUnit',
      'invalidMaxDecimal',
      'invalidPaid',
      'invalidPlcParam',
      'invalidBit',
      'invalidNodeId',
      'invalidStartAddress',
      'invalidSize',
      'invalidDBAddress',
      'invalidEndpointUrl',
      'invalidPlcIpAddr',
      'invalidSequence',
      'duplicateCombination',
      'duplicateNames',
      'duplicateNameFromDB',
      'duplicateCombinationinList',
      'parametersToUpload',
    ]),
    invalidArray() {
      return [
        // this.missingInformation,
        this.incorrectProtocol,
        this.invalidName,
        this.invalidDescript,
        this.invalidChineseDescript,
        this.invalidParameterCategory,
        this.invalidDataType,
        this.invalidMultiplicationFactor,
        this.invalidParameterUnit,
        this.invalidMaxDecimal,
        this.invalidPaid,
        this.invalidPlcParam,
        this.invalidBit,
        this.invalidNodeId,
        this.invalidStartAddress,
        this.invalidSize,
        this.invalidDBAddress,
        this.invalidEndpointUrl,
        this.invalidPlcIpAddr,
        this.invalidSequence,
        this.duplicateCombination,
        this.duplicateNames,
        this.duplicateNameFromDB,
        this.duplicateCombinationinList,
      ];
    },
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapMutations('parameterConfigurationMes', ['setCreateParam']),
    ...mapActions('parameterConfigurationMes', ['createParameterList']),
    async createParamsBtn() {
      this.savingData = true;
      const createResult = await this.createParameterList(this.parametersToUpload);
      this.dialog = false;
      if (createResult) {
        this.$root.$emit('passedFlagOfValidations', this.parametersToUpload);
        this.setAlert({
          show: true,
          type: 'success',
          message: 'CREATE_PARAMETER',
        });
        this.$root.$emit('getListofParams', true);
        this.$root.$emit('clearResponse', true);
      } else {
        this.setCreateParam(false);
        this.setAlert({
          show: true,
          type: 'error',
          message: 'CREATE_PARAMETER',
        });
      }
      this.savingData = false;
    },
    async cancelCreation() {
      this.$root.$emit('clearResponse', true);
      this.dialog = false;
    },
  },
};
</script>
