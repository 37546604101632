<template>
  <v-container fluid class="py-0" v-resize="setHeight">
    <portal to="app-extension">
      <v-row>
        <v-col cols="12" md="3">
          <v-responsive :max-width="200">
            <v-select
              filled
              dense
              hide-details
              :items="protocolList"
              item-value="name"
              v-model="selectedProtocol"
              item-text="name"
              @change="onSelectProtocol"
              label="Select Protocol"
            ></v-select>
          </v-responsive>
        </v-col>
        <v-col cols="12" md="9">
          <div style="float: right" class="mt-4">
            <v-btn
              small
              color="primary"
              class="text-none"
              :disabled="isAddButtonOK"
              @click="setAddParameterDialog(true)"
            >
              <v-icon small left>mdi-plus</v-icon>
              Add parameter
            </v-btn>
            <v-btn
              small
              color="primary"
              class="text-none ml-1"
              :disabled="isAddButtonOK"
              @click="getMonitorValues"
            >
              Monitor
            </v-btn>
            <v-btn
              small
              color="primary"
              outlined
              class="text-none ml-2"
              :disabled="!substationValue"
              @click="RefreshUI"
            >
              <v-icon small left>mdi-refresh</v-icon>
              Refresh
            </v-btn>
            <v-btn
              small
              color="error"
              outlined
              class="text-none ml-2"
              @click="confirmDialog = true"
              v-if="parameterList.length && parameterSelected.length"
            >
              <v-icon small left>mdi-delete</v-icon>
              Delete
            </v-btn>
            <v-btn
              small
              color="primary"
              v-if="substationValue && parameterSelected.length > 0"
              outlined
              class="text-none ml-2"
              @click="exportData"
            >
              Export
            </v-btn>
            <v-btn
              small
              color="primary"
              v-if="!(substationValue && parameterSelected.length > 0)"
              outlined
              class="text-none ml-2"
              :disabled="disableSampleDataBtn"
              @click="exportSampleData"
            >
              Export Sample File
            </v-btn>
            <v-btn
              small
              color="primary"
              outlined
              class="text-none ml-2"
              :disabled="isAddButtonOK"
              :loading="savingImport"
              @click="importData"
            >
              Import
            </v-btn>
            <input
              type="file"
              accept=".csv"
              ref="uploader"
              class="d-none"
              id="uploadFiles"
              @change="onFilesChanged"
            />
            <v-btn
              :disabled="!protocol"
              small
              color="primary"
              outlined
              class="text-none ml-2"
              @click="toggleFilter"
            >
              <v-icon small left>mdi-filter-variant</v-icon>
              Filters
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </portal>
    <v-row class="mt-1">
      <v-col cols="12" md="12">
        <span v-if="lineList.length && !!lineValue" class="ml-2">
          Line:
          <v-btn
            small
            color="normal"
            outlined
            class="text-none ml-2 text-truncate"
            @click="setLineValue('')"
          >
            <v-icon small left>mdi-close</v-icon>
            <div class="text-truncate" style="max-width: 100px">
              {{ lineList.filter((item) => item.id === lineValue)[0].name }}
            </div>
          </v-btn>
        </span>
        <span v-if="sublineList.length && !!sublineValue" class="ml-2">
          Subline:
          <v-btn
            small
            color="normal"
            outlined
            class="text-none ml-2"
            @click="setSublineValue('')"
          >
            <v-icon small left>mdi-close</v-icon>
            <div class="text-truncate" style="max-width: 100px">
              {{
                sublineList.filter((item) => item.id === sublineValue)[0].name
              }}
            </div>
          </v-btn>
        </span>
        <span v-if="stationList.length && !!stationValue" class="ml-2">
          Station:
          <v-btn
            small
            color="normal"
            outlined
            class="text-none ml-2"
            @click="setStationValue('')"
          >
            <v-icon small left>mdi-close</v-icon>
            <div class="text-truncate" style="max-width: 100px">
              {{
                stationList.filter((item) => item.id === stationValue)[0].name
              }}
            </div>
          </v-btn>
        </span>
        <span v-if="substationList.length && !!substationValue" class="ml-2">
          Sub-Station:
          <v-btn
            small
            color="normal"
            outlined
            class="text-none ml-2"
            @click="setSubstationValue('')"
          >
            <v-icon small left>mdi-close</v-icon>
            <div class="text-truncate" style="max-width: 100px">
              {{
                substationList.filter((item) => item.id === substationValue)[0]
                  .name
              }}
            </div>
          </v-btn>
        </span>
        <proceed-dialog ref="ProceedDialog" />
      </v-col>
    </v-row>
    <v-data-table
      v-if="parameterList.length"
      v-model="parameterSelected"
      :headers="headers"
      item-key="_id"
      :items="parameterList"
      :options="{ itemsPerPage: 5 }"
      fixed-header
      show-select
      :height="height"
      :loading="loadingData"
      :loading-text="this.initialMessage"
    >
      <!-- <template v-slot:[`item.name`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.name"
          @save="saveTableParameter(props.item, 'name')"
        >
          {{ props.item.name }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.name"
              label="Edit"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template> -->
      <template v-slot:[`item.description`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.description"
          large
          @save="saveTableParameter(props.item, 'description')"
        >
          <span
            v-if="props.item.description"
            >
            {{
              props.item.description.length > 10
                ? props.item.description.substr(0, 9) + "..."
                : props.item.description
            }}
          </span>
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-textarea
              :disabled="substationValue ? false : true"
              v-model="props.item.description"
              label="Edit"
              single-line
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.sequence`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.sequence"
          @save="saveTableParameter(props.item, 'sequence')"
        >
          {{ props.item.sequence }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.sequence"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.parametercategory`]="props">
        <v-select
          :disabled="(substationValue ? false : true) || saving"
          :items="categoryList"
          v-model="props.item.parametercategory"
          @change="saveTableParameter(props.item, 'parametercategory')"
          item-text="name"
          item-value="id"
          solo
          dense
          depressed
        ></v-select>
      </template>
      <template v-slot:[`item.datatype`]="props">
        <v-select
          :disabled="(substationValue ? false : true) || saving"
          :items="datatypeList"
          v-model="props.item.datatype"
          label="-"
          @change="saveTableParameter(props.item, 'datatype')"
          item-text="name"
          item-value="id"
          solo
          dense
          depressed
        ></v-select>
      </template>

      <template v-slot:[`item.min`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.min"
          @save="saveTableParameter(props.item, 'min')"
        >
          {{ props.item.min }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.min"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

       <template v-slot:[`item.criticalmin`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.criticalmin"
          @save="saveTableParameter(props.item, 'criticalmin')"
        >
          {{ props.item.criticalmin }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.criticalmin"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
       <template v-slot:[`item.criticalmax`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.criticalmax"
          @save="saveTableParameter(props.item, 'criticalmax')"
        >
          {{ props.item.criticalmax }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.criticalmax"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

       <template v-slot:[`item.max`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.max"
          @save="saveTableParameter(props.item, 'max')"
        >
          {{ props.item.max }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.max"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.bitnumber`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.bitnumber"
          @save="saveTableParameter(props.item, 'bitnumber')"
        >
          {{ props.item.bitnumber }}
          <v-icon
            small
            color="primary"
            :disabled="!substationValue || props.item.datatype !== 12"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="!substationValue || props.item.datatype !== 12"
              v-model="props.item.bitnumber"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.size`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.size"
          @save="saveTableParameter(props.item, 'size')"
        >
          {{ props.item.size }}
          <v-icon
            small
            color="primary"
            :disabled="!substationValue ||
            (selectedProtocol !== 'SQL' && props.item.datatype !== 11)"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="!substationValue ||
              (selectedProtocol !== 'SQL' && props.item.datatype !== 11)"
              v-model="props.item.size"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.startaddress`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.startaddress"
          @save="saveTableParameter(props.item, 'startaddress')"
        >
          {{ props.item.startaddress }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.startaddress"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.dbaddress`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.dbaddress"
          @save="saveTableParameter(props.item, 'dbaddress')"
        >
          {{ props.item.dbaddress }}
          <v-icon
            small
            color="primary"
            :disabled="substationValue ? false : true"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              :disabled="substationValue ? false : true"
              v-model="props.item.dbaddress"
              label="Edit"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog
      persistent
      scrollable
      v-model="confirmDialog"
      max-width="500px"
      fixed-header
      :height="tableHeight - 168"
    >
      <v-card>
        <v-card-title primary-title>
          <span> Please confirm </span>
          <v-spacer></v-spacer>
          <v-btn icon small @click="confirmDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text> Are you sure to delete the items selected ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none"
            :loading="saving"
            @click="handleDeleteParameter"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddParameter
      :station="stationValue"
      :substation="substationValue"
      :line="lineValue"
      :subline="sublineValue"
      :protocolList="protocolList"
      :tags="protoTags"
      v-if="addParameterDialog"
    />
    <ParameterFilter :station="stationValue" :substation="substationValue" />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import socketioclient from 'socket.io-client';
import CSVParser from '@shopworx/services/util/csv.service';
import ZipService from '@shopworx/services/util/zip.service';
import AddParameter from '../components/AddParameter.vue';
import ParameterFilter from '../components/ParameterFilter.vue';
import ProceedDialog from '../components/ProceedDialog.vue';
import elementsAndTags from '../data/elements';

export default {
  name: 'PlanScheduleView',
  data() {
    return {
      ProceedDialog: '',
      protoTags: [],
      comOptTags: ['description', 'chinesedescription'],
      protocolList: [
        {
          name: 'ETHERNET',
          id: 'ETHERNET',
          tagNames: ['size', 'plc_parameter', 'plcaddress'],
          hasSize: true,
          hasPlcParamName: true,
        },
        {
          name: 'MELSEC',
          id: 'MELSEC',
          tagNames: ['size', 'startaddress', 'bitnumber', 'plcaddress'],
          hasStartAddress: true,
          hasBitnumber: true,
          hasSize: true,
        },
        {
          name: 'MODBUS',
          id: 'MODBUS',
          tagNames: [
            'size',
            'startaddress',
            'bitnumber',
            'isbigendian',
            'isswapped',
            'plcaddress',
          ],
          hasStartAddress: true,
          hasBitnumber: true,
          hasSize: true,
        },
        {
          name: 'MQTT',
          id: 'MQTT',
          tagNames: [
            'devicetagname',
            'isformula',
            'formula',
          ],
          isformula: true,
          hasFormula: true,
          hasDevicetagname: true,
        },
        {
          name: 'OPCUA',
          id: 'OPCUA',
          tagNames: ['nodeid', 'endpointurl'],
          hasNodeID: true,
        },
        {
          name: 'SQL',
          id: 'SQL',
          tagNames: [
            'startoffset',
            'size',
            'divisionfactor',
            'isconversion',
          ],
          hasStartOffset: true,
          hasDivisionFactor: true,
          hasIsConversion: true,
          hasSize: true,
        },
        {
          name: 'SNAP7',
          id: 'SNAP7',
          tagNames: [
            'size',
            'dbaddress',
            'startaddress',
            'bitnumber',
            'isbigendian',
            'isswapped',
            'plcaddress',
          ],
          hasDBAddress: true,
          hasStartAddress: true,
          hasBitnumber: true,
          hasSize: true,
        },
      ],
      initialMessage: 'Please select protocol',
      loadingData: false,
      disableSampleDataBtn: true,
      parameterSelected: [],
      tableHeight: window.innerHeight,
      headers: [],
      commonHeaders: [
        {
          text: 'Number',
          value: 'number',
          width: 120,
        },
        {
          text: 'Line',
          value: 'line',
          width: 120,
        },
        {
          text: 'Subline',
          value: 'subline',
          width: 120,
        },
        {
          text: 'Station',
          value: 'station',
          width: 120,
        },
        {
          text: 'Substation',
          value: 'substation',
          width: 120,
        },
        {
          text: 'Parameter',
          value: 'name',
          width: 120,
          csv: true,
        },
        {
          text: 'Parameter Description',
          value: 'description',
          width: 200,
          csv: true,
        },
        {
          text: 'Sequence',
          value: 'sequence',
          csv: true,
        },
        {
          text: 'Category',
          value: 'parametercategory',
          csv: true,
        },
        {
          text: 'PA-ID',
          value: 'paid',
          csv: true,
        },
        {
          text: 'Data Type',
          value: 'datatype',
          csv: true,
        },
        {
          text: 'Multiplication Factor',
          value: 'multiplicationfactor',
          width: 80,
          csv: true,
        },
        {
          text: 'Max Decimal',
          value: 'maxdecimal',
          width: 80,
          csv: true,
        },
        {
          text: 'Parameter Unit',
          value: 'parameterunit',
          width: 80,
          csv: true,
        },
        {
          text: 'Min',
          value: 'min',
          width: 80,
          csv: true,
        },
        {
          text: 'Critical Min',
          value: 'criticalmin',
          width: 80,
          csv: true,
        },
        {
          text: 'Critical Max',
          value: 'criticalmax',
          width: 80,
          csv: true,
        },
        {
          text: 'Max',
          value: 'max',
          width: 80,
          csv: true,
        },
      ],
      dataMonitorHeaders: [
        {
          text: 'Monitor',
          value: 'monitorvalue',
          width: 130,
        },
        {
          text: 'Status',
          value: 'status',
          width: 130,
        },
      ],
      headersSnap7: [
        {
          text: 'Size',
          value: 'size',
          width: 80,
          csv: true,
        },
        {
          text: 'DB Address',
          value: 'dbaddress',
          width: 130,
          csv: true,
        },
        {
          text: 'Start Address',
          value: 'startaddress',
          width: 140,
          csv: true,
        },
        {
          text: 'Boolean Bit',
          value: 'bitnumber',
          width: 120,
          csv: true,
        },
      ],
      headersModbus: [
        {
          text: 'Size',
          value: 'size',
          width: 80,
          csv: true,
        },
        {
          text: 'Start Address',
          value: 'startaddress',
          width: 140,
          csv: true,
        },
        {
          text: 'Boolean Bit',
          value: 'bitnumber',
          width: 120,
          csv: true,
        },
      ],
      headersMelsec: [
        {
          text: 'Size',
          value: 'size',
          width: 80,
          csv: true,
        },
        {
          text: 'Start Address',
          value: 'startaddress',
          width: 140,
          csv: true,
        },
        {
          text: 'Boolean Bit',
          value: 'bitnumber',
          width: 120,
          csv: true,
        },
      ],
      headersEthernet: [
        {
          text: 'Size',
          value: 'size',
          width: 80,
          csv: true,
        },
        {
          text: 'PLC Parameter',
          value: 'plc_parameter',
          width: 140,
          csv: true,
        },
      ],
      headersMQTT: [
        {
          text: 'Device Tagname',
          value: 'devicetagname',
          csv: true,
        },
        {
          text: 'Is Formula',
          value: 'isformula',
          csv: true,
        },
        {
          text: 'Formula',
          value: 'formula',
          csv: true,
        },
      ],
      headersOPCUA: [
        {
          text: 'Node ID',
          value: 'nodeid',
          csv: true,
        },
      ],
      headersSQL: [
        {
          text: 'Start Offset', value: 'startoffset', width: 130, csv: true,
        },
        {
          text: 'Size', value: 'size', width: 80, csv: true,
        },
        {
          text: 'Division Factor', value: 'divisionfactor', width: 80, csv: true,
        },
        {
          text: 'Is Conversion', value: 'isconversion', width: 120, csv: true,
        },
      ],
      parameterListSave: [],
      confirmDialog: false,
      socket: null,
      saving: false,
      height: window.innerHeight,
      openDialog: false,
      savingImport: false,
    };
  },
  async created() {
    this.setExtendedHeader(true);
    this.zipService = ZipService;
    await this.getPageDataList();
    await this.getAssets();
    // TODO - Remove below intialization
    this.socket = socketioclient.connect('http://:10190');
    this.socket.on('connect', () => {});
  },
  mounted() {
    this.setHeight();
    this.ProceedDialog = this.$refs.ProceedDialog;
    // TODO - Change from emitter
    this.$root.$on('clearResponse', (data) => {
      const clear = data;
      if (clear) {
        this.resetUploadData();
        document.getElementById('uploadFiles').value = null;
        this.savingImport = false;
      }
    });
    this.$root.$on('getListofParams', (data) => {
      const getList = data;
      if (getList) {
        this.getParameterListRecords(this.parameterListQuery());
      }
    });
    this.$root.$on('passedFlagOfValidations', (data) => {
      if (data && data.length) {
        this.addTags(data);
      }
    });
  },
  destroyed() {
    this.setLineValue(null);
    this.setSublineValue(null);
    this.setStationValue(null);
    this.setSubstationValue(null);
    this.setParameterList([]);
    this.setProtocol(null);
    this.resetUploadData();
  },
  computed: {
    ...mapState('parameterConfigurationMes', [
      'addParameterDialog',
      'parameterList',
      'isApply',
      'lineList',
      'sublineList',
      'stationList',
      'substationList',
      'categoryList',
      'datatypeList',
      'lineValue',
      'sublineValue',
      'stationValue',
      'substationValue',
      'selectedParameterName',
      'selectedParameterCategory',
      'selectedParameterDatatype',
      'subStationElementDeatils',
      'createElementResponse',
      'validationsPassed',
      'protocolTags',
      'protocol',
      'assetId',
    ]),
    selectedProtocol: {
      get() {
        return this.protocol;
      },
      set(val) {
        this.setProtocol(val);
      },
    },
    isAddButtonOK() {
      if (
        this.lineValue
        && this.sublineValue
        && this.stationValue
        && this.substationValue
        && this.isApply
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    lineValue(val) {
      this.loadingData = true;
      if (!val) {
        this.setSublineValue(null);
        this.setStationValue(null);
        this.setSubstationValue(null);
      }
      this.getParameterListRecords(this.parameterListQuery());
      this.loadingData = false;
    },
    async sublineValue(val) {
      this.loadingData = true;
      if (!val) {
        this.setStationValue(null);
        this.setSubstationValue(null);
      }
      await this.getParameterListRecords(this.parameterListQuery());
      this.loadingData = false;
    },
    async stationValue(val) {
      this.loadingData = true;
      if (!val) {
        this.setSubstationValue(null);
      }
      await this.getParameterListRecords(this.parameterListQuery());
      this.loadingData = false;
    },
    async substationValue() {
      this.loadingData = true;
      await this.getParameterListRecords(this.parameterListQuery());
      this.loadingData = false;
    },
    parameterList(parameterList) {
      this.parameterListSave = parameterList.map((item) => ({ ...item }));
    },
  },
  methods: {
    ...mapMutations('helper', ['setAlert', 'setExtendedHeader']),
    ...mapMutations('parameterConfigurationMes', [
      'setAddParameterDialog',
      'toggleFilter',
      'setLineValue',
      'setSublineValue',
      'setStationValue',
      'setSubstationValue',
      'setSelectedParameterName',
      'setSelectedParameterCategory',
      'setSelectedParameterDatatype',
      'setParameterList',
      'setProtocol',
      'setCreateParam',
    ]),
    ...mapActions('parameterConfigurationMes', [
      'getPageDataList',
      'getSublineList',
      'getStationList',
      'getSubstationList',
      'getParameterListRecords',
      'getElementDetails',
      'updateParameter',
      'deleteParameter',
      'createParameter',
      'createParameterList',
      'downloadToPLC',
      'getSubStationIdElement',
      'createTags',
      'updateTagStatus',
      'createElement',
      'resetUploadData',
      'validateParameter',
      'getProtocolTags',
      'getAssets',
    ]),
    setHeight() {
      this.height = window.innerHeight - 212;
    },
    parameterListQuery() {
      let query = `?query=protocol=="${this.protocol}"`;
      if (this.lineValue) {
        query += `%26%26lineid==${this.lineValue}`;
      }
      if (this.sublineValue) {
        query += `%26%26sublineid=="${this.sublineValue}"`;
      }
      if (this.stationValue) {
        query += `%26%26stationid=="${this.stationValue}"`;
      }
      if (this.substationValue) {
        query += `%26%26substationid=="${this.substationValue}"`;
      }
      if (this.selectedParameterName) {
        query += `%26%26name=="${this.selectedParameterName}"`;
      }
      if (this.selectedParameterCategory) {
        query += `%26%26parametercategory=="${this.selectedParameterCategory}"`;
      }
      if (this.selectedParameterDatatype) {
        query += `%26%26datatype=="${this.selectedParameterDatatype}"`;
      }
      query += '&sortquery=sequence==1';
      return query;
    },
    async onSelectProtocol(value) {
      this.initialMessage = 'Loading...please wait';
      this.setProtocol(value);
      if (value) {
        this.loadingData = true;
        this.headers = [...this.commonHeaders];
        if (value === 'SNAP7') {
          this.headers.push(...this.headersSnap7);
        } else if (value === 'MELSEC') {
          this.headers.push(...this.headersMelsec);
        } else if (value === 'OPCUA') {
          this.headers.push(...this.headersOPCUA);
        } else if (value === 'ETHERNET') {
          this.headers.push(...this.headersEthernet);
        } else if (value === 'MODBUS') {
          this.headers.push(...this.headersModbus);
        } else if (value === 'SQL') {
          this.headers.push(...this.headersSQL);
        } else if (value === 'MQTT') {
          this.headers.push(...this.headersMQTT);
        }
        this.headers.push(...this.dataMonitorHeaders);

        await this.getParameterListRecords(this.parameterListQuery());
        await this.getProtocolTags({
          protocolList: this.protocolList,
          comOptTags: this.comOptTags,
        });
        this.loadingData = false;
        this.disableSampleDataBtn = false;
      } else {
        this.disableSampleDataBtn = true;
      }
    },
    async saveTableParameter(item, type) {
      if (type === 'sequence') {
        item[type] = Number(item[type]);
      }
      const value = item[type];
      const parameterListSave = [...this.parameterListSave];
      // TO-DO: Move to dedicated function
      if (!value) {
        this.setAlert({
          show: true,
          type: 'error',
          message: `${type}_can_not_be_empty`,
        });
        await this.getParameterListRecords(this.parameterListQuery());
        return;
      }
      if (type === 'name') {
        if (
          parameterListSave.some(
            (parameter) => value.toLowerCase().split(' ').join('')
              === parameter.name.toLowerCase().split(' ').join(''),
          )
        ) {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'parameter_name_is_present',
          });
          await this.getParameterListRecords(this.parameterListQuery());
          return;
        }
      }
      if (type === 'size') {
        if (value <= 0) {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'Size is Integer',
          });
          await this.getParameterListRecords(this.parameterListQuery());
          return;
        }
      }
      if (item.datatype !== 12) {
        if (type === 'dbaddress') {
          const isRepeat = parameterListSave.some(
            (parameter) => parameter.datatype !== 12
              && Number(item.dbaddress) === parameter.dbaddress
              && Number(item.startaddress) === parameter.startaddress,
          );
          if (isRepeat) {
            this.setAlert({
              show: true,
              type: 'error',
              message: 'parameter_dbaddress_is_present',
            });
            await this.getParameterListRecords(this.parameterListQuery());
            return;
          }
        }
        if (type === 'startaddress') {
          const isRepeat = parameterListSave.some(
            (parameter) => parameter.datatype !== 12
              && Number(item.dbaddress) === parameter.dbaddress
              && Number(item.startaddress) === parameter.startaddress,
          );
          if (isRepeat) {
            this.setAlert({
              show: true,
              type: 'error',
              message: 'parameter_startaddress_is_present',
            });
            await this.getParameterListRecords(this.parameterListQuery());
            return;
          }
        }
      } else {
        if (type === 'dbaddress') {
          const isRepeat = parameterListSave.some(
            (parameter) => parameter.datatype === 12
              && Number(item.dbaddress) === parameter.dbaddress
              && Number(item.startaddress) === parameter.startaddress
              && item.bitnumber === parameter.bitnumber,
          );
          if (isRepeat) {
            this.setAlert({
              show: true,
              type: 'error',
              message: 'parameter_dbaddress_is_present',
            });
            await this.getParameterListRecords(this.parameterListQuery());
            return;
          }
        }
        if (type === 'startaddress') {
          const isRepeat = parameterListSave.some(
            (parameter) => parameter.datatype === 12
              && Number(item.dbaddress) === parameter.dbaddress
              && Number(item.startaddress) === parameter.startaddress
              && item.bitnumber === parameter.bitnumber,
          );
          if (isRepeat) {
            this.setAlert({
              show: true,
              type: 'error',
              message: 'parameter_startaddress_is_present',
            });
            await this.getParameterListRecords(this.parameterListQuery());
            return;
          }
        }
        if (type === 'bitnumber') {
          const isRepeat = parameterListSave.some(
            (parameter) => parameter.datatype === 12
              && Number(item.dbaddress) === parameter.dbaddress
              && Number(item.startaddress) === parameter.startaddress
              && item.bitnumber === parameter.bitnumber,
          );
          if (isRepeat) {
            this.setAlert({
              show: true,
              type: 'error',
              message: 'Boolean_Bit_is_present',
            });
            await this.getParameterListRecords(this.parameterListQuery());
            return;
          }
        }
      }
      let selectedDatatypeItem = {};
      const parameterItem = parameterListSave.filter(
        (parameter) => item._id === parameter._id,
      )[0];
      // let query = `?query=id=='${parameterItem.id}'`;
      const payload = {};
      if (type === 'datatype') {
        [selectedDatatypeItem] = this.datatypeList.filter(
          (datatype) => value === datatype.id,
        );
        // query = `?query=id=='${parameterItem.id}'&isbigendian==
        // ${selectedDatatypeItem.isbigendian === 1}
        // &isswapped==${selectedDatatypeItem.isswapped === 1}`;
        payload.isbigendian = selectedDatatypeItem.isbigendian === 1;
        payload.isswapped = selectedDatatypeItem.isswapped === 1;
        if (
          selectedDatatypeItem.name !== 'Boolean'
          && selectedDatatypeItem.name !== 'String'
        ) {
          payload.size = selectedDatatypeItem.size;
        }
      }
      payload[type] = value;
      this.saving = true;
      const updateResult = await this.updateParameter({
        id: parameterItem._id,
        payload,
      });
      if (type === 'description') {
        await this.updateTagDescription(item, value);
      }
      this.saving = false;
      if (updateResult) {
        this.setAlert({
          show: true,
          type: 'success',
          message: `update_${type}`,
        });
      }
      await this.getParameterListRecords(this.parameterListQuery());
    },
    async updateTagDescription(item, value) {
      let payloadData = [];
      let subStationElementDeatils = await this.getSubStationIdElement(
        `production_${item.substationid}`,
      );
      if (subStationElementDeatils && subStationElementDeatils.tags) {
        subStationElementDeatils.tags.forEach((master) => {
          if (master.tagName === item.name) {
            payloadData.push({
              tagId: master.id,
              elementId: master.elementId,
              tagDescription: value,
            });
          }
        });
        if (payloadData.length) {
          await this.updateTagStatus(payloadData);
        }
      }
      subStationElementDeatils = null;
      subStationElementDeatils = await this.getSubStationIdElement(`process_${item.substationid}`);
      payloadData = [];
      if (subStationElementDeatils && subStationElementDeatils.tags) {
        subStationElementDeatils.tags.forEach((master) => {
          if (master.tagName === item.name) {
            payloadData.push({
              tagId: master.id,
              elementId: master.elementId,
              tagDescription: value,
            });
          }
        });
        if (payloadData.length) {
          await this.updateTagStatus(payloadData);
        }
      }
      const listTags = await this.getElementDetails('traceability');
      payloadData = [];
      if (listTags && listTags.tags) {
        listTags.tags.forEach((master) => {
          if (master.tagName === `${item.substationid}_${item.name}`) {
            payloadData.push({
              tagId: master.id,
              elementId: master.elementId,
              tagDescription: value,
            });
          }
        });
        if (payloadData.length) {
          await this.updateTagStatus(payloadData);
        }
      }
    },
    async handleDeleteParameter() {
      const results = await Promise.all(
        this.parameterSelected.map((parameter) => this.deleteParameter(parameter.id)),
      );
      const selectedSubStaionlist = this.parameterSelected.map(
        (sl) => sl.substationid,
      );
      let subStationElementDeatils = await this.getSubStationIdElement(
        `production_${selectedSubStaionlist[0]}`,
      );
      // DISABLE ONLY SELECTED TAGS - PRODUCTION SUBSTATION ELEMENT
      let payloadData = [];
      if (subStationElementDeatils && subStationElementDeatils.tags) {
        subStationElementDeatils.tags.forEach((master) => {
          this.parameterSelected.forEach((selected) => {
            if (master.tagName === selected.name) {
              payloadData.push({
                tagId: master.id,
                elementId: master.elementId,
                status: 'INACTIVE',
              });
            }
          });
        });
        if (payloadData.length) {
          await this.updateTagStatus(payloadData);
        }
      }
      // DISABLE ONLY SELECTED TAGS - PROCESS SUBSTATION ELEMENT
      subStationElementDeatils = null;
      subStationElementDeatils = await this.getSubStationIdElement(`process_${selectedSubStaionlist[0]}`);
      payloadData = [];
      if (subStationElementDeatils && subStationElementDeatils.tags) {
        subStationElementDeatils.tags.forEach((master) => {
          this.parameterSelected.forEach((selected) => {
            if (master.tagName === selected.name) {
              payloadData.push({
                tagId: master.id,
                elementId: master.elementId,
                status: 'INACTIVE',
              });
            }
          });
        });
        if (payloadData.length) {
          await this.updateTagStatus(payloadData);
        }
      }
      // on delete update the tags of traceability element
      const listTags = await this.getElementDetails('traceability');
      payloadData = [];
      if (listTags && listTags.tags) {
        listTags.tags.forEach((master) => {
          this.parameterSelected.forEach((selected) => {
            if (master.tagName === `${selected.substationid}_${selected.name}`) {
              payloadData.push({
                tagId: master.id,
                elementId: master.elementId,
                status: 'INACTIVE',
              });
            }
          });
        });
        if (payloadData.length) {
          await this.updateTagStatus(payloadData);
        }
      }
      if (results.every((bool) => bool === true)) {
        this.saving = true;
        await this.getParameterListRecords(this.parameterListQuery());
        this.saving = false;
        this.confirmDialog = false;
        this.parameterSelected = [];
        this.setAlert({
          show: true,
          type: 'success',
          message: 'DELETE_PARAMETER',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'DELETE_PARAMETER',
        });
      }
    },
    async RefreshUI() {
      this.loadingData = true;
      await this.getParameterListRecords(this.parameterListQuery());
      this.loadingData = false;
    },
    getMonitorValues() {
      this.downloadFromPLC();
    },
    async downloadFromPLC() {
      const object = {
        lineid: Number(this.lineValue),
        sublineid: this.sublineValue,
        substationid: this.substationValue,
      };
      this.socket.on(
        `update_parameter_${object.lineid}_${object.sublineid}_${object.substationid}`,
        (data) => {
          if (data) {
            this.parameterList.forEach((element) => {
              this.$set(element, 'monitorvalue', data[element.name]);
            });
          }
        },
      );
      await this.downloadToPLC(object);
    },
    async exportData() {
      const lineName = this.lineList.filter(
        (item) => this.lineValue === item.id,
      ).length
        ? this.lineList.filter((item) => this.lineValue === item.id)[0].name
        : 'None';
      const sublineName = this.sublineList.filter(
        (item) => this.sublineValue === item.id,
      ).length
        ? this.sublineList.filter((item) => this.sublineValue === item.id)[0]
          .name
        : 'None';
      const stationName = this.stationList.filter(
        (item) => this.stationValue === item.id,
      ).length
        ? this.stationList.filter((item) => this.stationValue === item.id)[0]
          .name
        : 'None';
      const substationName = this.substationList.filter(
        (item) => this.substationValue === item.id,
      ).length
        ? this.substationList.filter(
          (item) => this.substationValue === item.id,
        )[0].name
        : 'None';
      const fileName = `${lineName}-${sublineName}-${stationName}-${substationName}`;
      const parameterSelected = this.parameterSelected.map((item) => ({
        ...item,
      }));
      const csvContent = [];
      const column = this.headers
        .filter((header) => header.csv)
        .map((data) => data.value);
      parameterSelected.forEach((parameter) => {
        const arr = [];
        column.forEach((key) => {
          arr.push(parameter[key]);
        });
        csvContent.push(arr);
      });
      const csvParser = new CSVParser();
      const content = csvParser.unparse({
        fields: column,
        data: csvContent,
      });
      this.addToZip({
        fileName: `${fileName}.csv`,
        fileContent: content,
      });
      const zip = await this.zipService.generateZip();
      this.zipService.downloadFile(zip, `${fileName}.zip`);
      this.zipService.removeFile({ fileName: `${fileName}.csv` });
      this.setAlert({
        show: true,
        type: 'success',
        message: 'EXPORT_PARAMETER_LIST',
      });
      const emptyContent = [];
      this.parameterSelected = emptyContent;
      // return content;
    },
    async exportSampleData() {
      const fileName = `${this.protocol}-sample-parameter-file`;
      const column = this.headers
        .filter((header) => header.csv)
        .map((data) => data.value);
      column.splice(2, 0, 'chinesedescription');
      column.splice(3, 0, 'protocol');
      const csvContent = [
        'parametername' /* NAME */,
        'description-text' /* DESCRIPTION */,
        '描述文字' /* CHINESE DESCRIPTION */,
        1 /* SEQUENCE */,
        '35' /* PARAMETER CATEGORY */,
        1 /* PA-ID */,
        5 /* DATA TYPE */,
        1 /* MULTIPLICATION FACTOR */,
        3 /* MAX DECIMAL */,
        1 /* PARAMETER UNIT */,
        1 /* Min */,
        2 /* Critical Min */,
        8 /* Critical Max */,
        10 /* Max */,
      ];
      if (this.protocol === 'ETHERNET') {
        csvContent.splice(3, 0, 'ETHERNET');
        csvContent.push(2); /* SIZE */
        csvContent.push('Shopworx.Handshake.PLCOnline'); /* PLC PARAMETER */
        csvContent.push('station1'); /* PREFIX */
      } else if (this.protocol === 'MELSEC') {
        csvContent.splice(3, 0, 'MELSEC');
        csvContent.push(2); /* SIZE */
        // csvContent.push(1002); /* DB ADDRESS */
        csvContent.push(1001); /* START ADDRESS */
        csvContent.push(1); /* BIT NUMBER */
      } else if (this.protocol === 'OPCUA') {
        csvContent.splice(3, 0, 'OPCUA');
        csvContent.push(
          'ns=4;s=GVL_LinMesOPC.g_Beckhoff.Handshake.xPLCLive',
        ); /* NODE ID */
      } else if (this.protocol === 'SNAP7') {
        csvContent.splice(3, 0, 'SNAP7');
        csvContent.push(2); /* SIZE */
        csvContent.push(1002); /* DB ADDRESS */
        csvContent.push(1001); /* START ADDRESS */
        csvContent.push(1); /* BIT NUMBER */
      } else if (this.protocol === 'MODBUS') {
        csvContent.splice(3, 0, 'MODBUS');
        csvContent.push(2); /* SIZE */
        csvContent.push(1001); /* START ADDRESS */
        csvContent.push(1); /* BIT NUMBER */
      } else if (this.selectedProtocol === 'SQL') {
        csvContent.splice(3, 0, 'SQL');
        csvContent.push(1001); /* START OFFSET */
        csvContent.push(2); /* SIZE */
        csvContent.push(1); /* DIVISION FACTOR */
        csvContent.push(true); /* IS CONVERSION */
      } else if (this.selectedProtocol === 'MQTT') {
        csvContent.splice(3, 0, 'MQTT');
        csvContent.push('ai1'); /* DEVICE TAGNAME */
        csvContent.push(false); /* IS FORMULA */
        csvContent.push('((14 - 0)/(20 - 4))*(val - 4) + 0'); /* FORMULA */
      }
      const csvParser = new CSVParser();
      const content = csvParser.unparse({
        fields: column,
        data: csvContent,
      });
      this.addToZip({
        fileName: `${fileName}.csv`,
        fileContent: content,
      });
      const zip = await this.zipService.generateZip();
      this.zipService.downloadFile(zip, `${fileName}.zip`);
      this.zipService.removeFile({ fileName: `${fileName}.csv` });
      this.setAlert({
        show: true,
        type: 'success',
        message: 'SAMPLE_FILE_DOWNLOAD',
      });
      return content;
    },
    importData() {
      this.$refs.uploader.click();
    },
    async onFilesChanged(e) {
      // this.validateFlag = true;
      this.savingImport = true;
      const files = e && e !== undefined ? e.target.files : null;
      const ext = /^.+\.([^.]+)$/.exec(files[0].name);
      const getFileExtension = ext == null ? 'Null input from upload' : ext[1];
      if (getFileExtension !== 'csv' && getFileExtension !== 'CSV') {
        this.savingImport = false;
        this.setAlert({
          show: true,
          type: 'error',
          message: 'UPLOAD_ONLY_CSV',
        });
        document.getElementById('uploadFiles').value = null;
        return;
      }
      const csvParser = new CSVParser();
      const optionObject = {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        encoding: 'UTF-8',
      };
      const { data } = await csvParser.parse(files[0], optionObject);
      if (!data.length) {
        this.savingImport = false;
        this.setAlert({
          show: true,
          type: 'error',
          message: 'IMPORT_EMPTY_FILE',
        });
      }
      this.resetUploadData();
      this.validateParameter(data);
      this.$root.$emit('parameterCreation', true);
    },
    async addTags(uploadedParameter) {
      await this.getParameterListRecords(this.parameterListQuery());
      if (uploadedParameter && uploadedParameter.length) {
        this.savingImport = true;
        const processTagCategory = this.categoryList
          .filter((category) => category.createprocesstags)
          .map((cat) => +cat.id);
        const productionTagCategory = this.categoryList
          .filter((category) => category.createproductiontags)
          .map((cat) => +cat.id);
        // const processTagCategory = [15, 17, 18];
        const responseMessages = [];
        let isError = false;
        if (processTagCategory.length) {
          const processSubstationDetails = await this.getElementDetails(
            `process_${this.substationValue}`,
          );
          const processElementId = processSubstationDetails.element.id;
          const processTags = [];
          const processParameterTags = this.generateTagsData(
            uploadedParameter,
            processElementId,
            processTagCategory,
            null,
          );
          if (processParameterTags && processParameterTags.length) {
            processTags.push(...processParameterTags);
          }
          if (processTags.length) {
            const response = await this.createTags(processTags);
            if (response) {
              responseMessages.push('PROCESS_TAGS_CREATED');
            } else {
              isError = true;
              responseMessages.push('PROCESS_TAGS_CREATION_ERROR');
            }
          } else {
            responseMessages.push('PROCESS_TAGS_NOT_REQUIRED');
          }
        } else {
          responseMessages.push('PROCESS_TAGS_NOT_REQUIRED');
        }
        if (productionTagCategory) {
          const productionSubstationDetails = await this.getElementDetails(
            `production_${this.substationValue}`,
          );
          const productionElementId = productionSubstationDetails.element.id;
          const productionTags = [];
          const productionParameterTags = this.generateTagsData(
            uploadedParameter,
            productionElementId,
            productionTagCategory,
            null,
          );
          if (productionParameterTags && productionParameterTags.length) {
            productionTags.push(...productionParameterTags);
          }
          if (productionTags.length) {
            const response = await this.createTags(productionTags);
            if (response) {
              responseMessages.push('PRODUCTION_TAGS_CREATED');
            } else {
              isError = true;
              responseMessages.push('PRODUCTION_TAGS_CREATION_ERROR');
            }
          } else {
            responseMessages.push('PRODUCTION_TAGS_NOT_REQUIRED');
          }
          const traceabilityElementDetails = await this.getElementDetails(
            'traceability',
          );
          const traceabilityTags = [];
          if (!traceabilityElementDetails) {
            const elementProperties = {
              ...elementsAndTags[0].element,
            };
            const elementId = await this.createElement(elementProperties);
            const tagDetails = {
              ...elementsAndTags[0].tags,
            };
            tagDetails.tagName = 'mainid';
            tagDetails.tagDescription = 'mainid';
            tagDetails.emgTagType = 'String';
            tagDetails.elementId = elementId;
            tagDetails.assetId = this.assetId;
            traceabilityTags.push(tagDetails);
            const traceabilityParameterTags = this.generateTagsData(
              uploadedParameter,
              elementId,
              productionTagCategory,
              this.substationValue,
            );
            if (traceabilityParameterTags && traceabilityParameterTags.length) {
              traceabilityTags.push(...traceabilityParameterTags);
            }
          } else {
            const elementId = traceabilityElementDetails.element.id;
            const traceabilityParameterTags = this.generateTagsData(
              uploadedParameter,
              elementId,
              productionTagCategory,
              this.substationValue,
            );
            if (traceabilityParameterTags && traceabilityParameterTags.length) {
              traceabilityTags.push(...traceabilityParameterTags);
            }
          }
          if (traceabilityTags.length) {
            const response = await this.createTags(traceabilityTags);
            if (response) {
              responseMessages.push('TRACEABILITY_TAGS_CREATED');
            } else {
              isError = true;
              responseMessages.push('TRACEABILITY_TAGS_CREATION_ERROR');
            }
          } else {
            responseMessages.push('TRACEABILITY_TAGS_NOT_REQUIRED');
          }
        } else {
          responseMessages.push('TRACEABILITY_TAGS_NOT_REQUIRED');
          responseMessages.push('PRODUCTION_TAGS_NOT_REQUIRED');
        }
        let messageType = 'success';
        if (isError) {
          messageType = 'error';
        }
        this.setAlert({
          show: true,
          type: messageType,
          message: responseMessages,
        });
      }
      this.savingImport = false;
      document.getElementById('uploadFiles').value = null;
    },
    generateTagsData(parameters, elementId, allowedCategories, prefix) {
      const tagArray = [];
      let subStationName = '';
      if (prefix) {
        subStationName = this.substationList
          .filter((item) => item.id === prefix)[0].name;
      }
      parameters.forEach((item) => {
        if (allowedCategories.includes(+item.parametercategory)) {
          const tagDetails = {
            ...elementsAndTags[0].tags,
          };
          let tagName = item.name.toLowerCase().trim();
          let tagDescription = item.description;
          if (prefix) {
            tagName = `${prefix}_${tagName}`;
            tagDescription = `${subStationName}_${tagDescription}`;
          }
          tagDetails.elementId = elementId;
          tagDetails.tagName = tagName;
          tagDetails.tagDescription = tagDescription;
          const dataTypeName = this.datatypeList.filter(
            (datatype) => Number(datatype.id) === Number(item.datatype),
          )[0].name;
          let dataType = 'Double';
          if (dataTypeName === 'String') {
            dataType = 'String';
          } else if (dataTypeName === 'Boolean') {
            dataType = 'Boolean';
          }
          tagDetails.emgTagType = dataType;
          tagDetails.assetId = this.assetId;
          tagArray.push(tagDetails);
        }
      });
      return tagArray;
    },
    addToZip(file) {
      this.zipService.addFile(file);
    },
  },
  components: {
    AddParameter,
    ParameterFilter,
    ProceedDialog,
  },
};
</script>

<style>
.planScheduleView .stick {
  /* position: sticky;
  position: -webkit-sticky;
  top: 60px; */
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
  z-index: 1;
}
.planScheduleView .card-border {
  border-left: 4px solid green;
}
.planScheduleView .orange {
  text-emphasis-color: orange;
}
.planScheduleView .green {
  background-color: green;
}
.planScheduleView .v-select {
  height: 30px;
}
.planScheduleView .v-input__slot {
  width: 150px;
}
.planScheduleView
  .v-text-field.v-text-field--solo.v-input--dense
  > .v-input__control {
  min-height: 30px;
}
</style>
