<template>
  <div>
    <portal to="app-header">
      {{ $t("parameterConfiguration") }}
      <v-btn icon small class="ml-4 mb-1">
        <v-icon v-text="'$info'"></v-icon>
      </v-btn>
      <v-btn icon small class="ml-2 mb-1">
        <v-icon v-text="'$settings'"></v-icon>
      </v-btn>
    </portal>
    <template>
      <parameter-schedule-view />
    </template>
  </div>
</template>

<script>
import ParameterScheduleView from './ParameterScheduleView.vue';

export default {
  name: 'ParameterConfigurationMes',
  components: {
    ParameterScheduleView,
  },
  data() {
    return {
      parameterView: 0,
      loading: false,
    };
  },
  created() {
    this.loading = true;
    this.parameterView = 0;
    this.loading = false;
  },
  methods: {},
};
</script>
