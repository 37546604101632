export default [
  {
    element: {
      categoryType: 'ASSET',
      collectionName: 'provisioning',
      elementName: 'traceability',
      elementDescription: 'Traceability',
      status: 'ACTIVE',
      elementType: 'PROVISIONING',
      uniqueTagName: '',
      uniqueTagValue: 0,
      uniqueTagStartValue: 0,
      uniqueTagValuePrefix: '',
      uniqueTagValueSuffix: '',
      businessTimeTagsRequired: false,
      optional: false,
      assetBased: true,
      uniqueTag: false,
      assetId: 4,
    },
    tags:
    {
      tagName: '',
      emgTagType: '',
      tagDescription: '',
      elementId: '',
      assetId: 4,
      status: 'ACTIVE',
      derivedField: false,
      derivedFunctionName: null,
      derivedFieldType: null,
      filter: false,
      filterFromElementName: null,
      filterFromTagName: null,
      required: true,
      hide: false,
      tagOrder: 2,
      filterFromList: null,
      mode: '',
    },
  },
];
